<template>
  <b-form @submit.prevent="handleSubmit">
    <b-card>
      <b-col
        cols="6"
        md="3"
      >
        <b-form-group
          class="required"
          label="Role Name"
          label-for="role-name"
        >
          <b-form-input
            id="role-name"
            v-model="roleName"
            placeholder="Enter Role Name"
            required
            :disabled="isRoleNameDisabled"
          />
        </b-form-group>
      </b-col>
    </b-card>
    <b-card no-body>
      <div>
        <!-- table -->
        <b-overlay
          id="overlay-background"
          :show="loading"
          variant="light"
          opacity="0.30"
          blur="10px"
          rounded="sm"
        >
          <b-card no-body>
            <b-tabs
              ref="roleTabBar"
              nav-wrapper-class="nav-horizontal"
              card
              pills
              align="center"
            >
              <b-tab
                v-for="(permissionGroup, index) in permissionData.permission_groups ? permissionData.permission_groups.filter(group => group.parent_id === (permissionData.permission_groups.filter(grp=>grp.name === 'Manage Merchant Portal'))[0].id) : []"
                :key="index"
                title-item-class="d-flex justify-content-start shadow mx-1 mt-1"
              >
                <template #title>
                  <b-card-text class="mx-1 font-weight-bolder">
                    {{ permissionGroup.name }}
                  </b-card-text>
                </template>
                <b-card>
                  <div
                    v-for="(permissionSubGroup, subgroupIndex) in permissionData.permission_groups ? permissionData.permission_groups.filter(subgroup => subgroup.parent_id === permissionGroup.id ) : []"
                    :key="subgroupIndex"
                    class="d-flex flex-column flex-md-row justify-content-between align-items-center my-1 shadow-lg rounded"
                  >
                    <b-col
                      cols="12"
                      md="2"
                      class="rounded h-100"
                    >
                      <div class="py-2 py-md-0 font-weight-bolder text-center">
                        {{ permissionSubGroup.name }}
                      </div>
                    </b-col>
                    <div
                      class="d-flex justify-content-start w-100"
                      style="border-radius: 0px 0px 0px 0px; box-shadow: -3px 0px 12px -8px #888888; "
                    >
                      <div style="width:75px;">
                        <b-tooltip
                          :target="`select-all-${permissionSubGroup.id}`"
                          triggers="hover"
                          variant="primary"
                        >
                          Select All
                        </b-tooltip>
                        <b-button
                          :id="`select-all-${permissionSubGroup.id}`"
                          class="mr-md-2 h-100 rounded-0"
                          style="padding-right:3px;padding-left:3px;"
                          variant="primary"
                          :disabled="isRestricted"
                          @click="selectSubGroup(permissionData.permissions ? permissionData.permissions.filter(permission => permission.parent_id === permissionSubGroup.id || permission.parent_id === permissionGroup.id ) : [])"
                        >
                          <feather-icon
                            icon="ChevronsRightIcon"
                            class="text-white"
                            size="20"
                          />
                        </b-button>
                      </div>
                      <div class="d-flex flex-wrap justify-content-start w-100">
                        <b-col
                          v-for="(permission, permissionIndex) in permissionData.permissions ? permissionData.permissions.filter(permission => permission.parent_id === permissionSubGroup.id || permission.parent_id === permissionGroup.id ) : []"
                          :key="permissionIndex"
                          cols="6"
                          md="3"
                          class="my-2"
                        >
                          <b-form-checkbox
                            v-model="permissionList[permission.id]"
                            :value="true"
                            :unchecked-value="false"
                            class="mr-2"
                            style="margin-top: 10px; margin-bottom: 10px;"
                            :disabled="isRestricted"
                          >
                            <div class="ml-1">
                              {{ (permission.name.charAt(0).toUpperCase() + permission.name.slice(1)).replaceAll('-', ' ') }}
                            </div>
                          </b-form-checkbox>
                        </b-col>
                      </div>
                      <div>
                        <b-tooltip
                          :target="`deselect-all-${permissionSubGroup.id}`"
                          triggers="hover"
                          variant="danger"
                        >
                          Deselect All
                        </b-tooltip>
                        <b-button
                          :id="`deselect-all-${permissionSubGroup.id}`"
                          class="h-100 rounded-0"
                          style="padding-right:3px;padding-left:3px;"
                          variant="danger"
                          :disabled="isRestricted"
                          @click="deselectSubGroup(permissionData.permissions ? permissionData.permissions.filter(permission => permission.parent_id === permissionSubGroup.id || permission.parent_id === permissionGroup.id ) : [])"
                        >
                          <feather-icon
                            icon="ChevronsLeftIcon"
                            class="text-white"
                            size="20"
                          />
                        </b-button>
                      </div>
                    </div>
                  </div>
                </b-card>
              </b-tab>
            </b-tabs>
          </b-card>
        </b-overlay>
        <div class="d-flex justify-content-end mr-3 mb-3 ">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            type="submit"
            class="py-1 px-4"
            :disabled="isRestricted"
          >
            {{ edit ? 'Update' : 'Add' }} Role
          </b-button>
        </div>
      </div>
    </b-card>
  </b-form>
</template>

<script>
import {
  BForm,
  BCard,
  BCardText,
  BCol,
  BButton,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BOverlay,
  BTooltip,
  BTabs,
  BTab,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import { RepositoryFactory } from '@/repository/RepositoryFactory'
import {
  mapGetters,
} from 'vuex'

const RoleRepository = RepositoryFactory.get('role')
const ResourceRepository = RepositoryFactory.get('resource')

export default {
  directives: {
    Ripple,
  },
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BCard,
    BCardText,
    BCol,
    BButton,
    BTabs,
    BTab,
    BOverlay,
    BTooltip,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return {
      loading: false,
      roleName: '',
      permissionData: {},
      permissionList: {},
      edit: false,
      roleId: '',
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
    // check currunt user role is merchant super admin
    isCurrentUserIsAdmin() {
      return this.currentUser.role.name === 'Merchant Super Admin'
    },
    // check user trying to edit own role
    isUserEditingOwnRole() {
      return this.currentUser.role.name === this.roleName
    },
    // check other user trying to edit super admin role
    isOtherUserEditingMerchantSuperAdmin() {
      return !this.isCurrentUserIsAdmin && this.roleName === 'Merchant Super Admin'
    },
    // check super admin trying to edit other role or other user trying to edit super admin role
    isRestricted() {
      return this.isUserEditingOwnRole || this.isOtherUserEditingMerchantSuperAdmin
    },
    // disable to edit role name input
    isRoleNameDisabled() {
      return this.roleName === 'Merchant Super Admin'
    },
  },
  async mounted() {
    // eslint-disable-next-line no-underscore-dangle
    this.__preserveTabOnRefresh('roleTabBar', 'roleTabIndex')
    const { roleId } = this.$route.params
    await this.fetchDataPermissionlist()
    if (roleId) {
      this.edit = true
      this.roleId = roleId
      await this.getRoleDetails(roleId)
    }
  },
  methods: {
    async fetchDataPermissionlist() {
      this.loading = true
      try {
        const { data } = (await ResourceRepository.getPermissionTree())
        this.permissionData = data
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    async getRoleDetails(id) {
      this.loading = true
      try {
        const { data } = (await RoleRepository.getRoleDetails(id)).data
        this.roleName = data.name
        data.permissions.forEach(permission => {
          this.permissionList[permission.id] = true
        })
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    async handleSubmit() {
      this.loading = true
      const permissions = []
      // eslint-disable-next-line no-restricted-syntax
      for (const key of Object.keys(this.permissionList)) {
        if (this.permissionList[key]) {
          permissions.push(key)
        }
      }
      try {
        const payload = { name: this.roleName, description: '', permissions }
        if (this.edit) {
          await RoleRepository.updateRole(this.roleId, payload)
        } else {
          await RoleRepository.createRole(payload)
        }
        this.showSuccessMessage(`Role ${this.edit ? 'updated' : 'created'} successfully`)
        setTimeout(() => {
          this.$router.push({ name: 'roles' })
        }, 2500)
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    selectSubGroup(subgroupPermissions) {
      subgroupPermissions.forEach(permission => {
        this.permissionList[permission.id] = true
        this.permissionList = JSON.parse(JSON.stringify(this.permissionList))
      })
    },
    deselectSubGroup(subgroupPermissions) {
      subgroupPermissions.forEach(permission => {
        this.permissionList[permission.id] = false
        this.permissionList = JSON.parse(JSON.stringify(this.permissionList))
      })
    },
  },
}
</script>
<style lang="scss">
@import '../@core/scss/vue/libs/vue-select.scss';
</style>
